import { FC, lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import LdsRoller from "../components/ldsRoller";
import PrivateRoute from "../components/privateRoute";
import Alert from "../components/alert";
import ErrorBoundary from "../components/errorBoundary";
const LoginPage = lazy(() => import("../pages/loginPage"));
const Dashboard = lazy(() => import("./dashboard"));

interface Props {}
const App: FC<Props> = () => {
  return (
    <div className="App">
      <Suspense
        fallback={
          <div className="lds-container">
            <LdsRoller />
          </div>
        }
      >
        <Switch>
          <Route path="/login">
            <ErrorBoundary>
              <LoginPage />
            </ErrorBoundary>
          </Route>
          <Route path="/404">
            <h1>لم يتم العثور على الصفحة المطلوبة!</h1>
          </Route>
          <PrivateRoute path="/">
            <ErrorBoundary>
              <Dashboard />
            </ErrorBoundary>
          </PrivateRoute>
        </Switch>
      </Suspense>
      <Alert />
    </div>
  );
};

export default App;
