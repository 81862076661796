import { merge } from "../../utils/helpers";
import { AsyncTypes as Types, EntitiesState, QuestionTypes } from "../types";
import asyncReducer from "./asyncReducer";

const initialState: EntitiesState = {
  joins: {},
  users: {},
  admins: {},
  news: {},
  courses: {},
  answers: {},
  questions: {},
  competitions: {},
  support: {},
  msgs: {},
};
// Updates an entity cache in response to any action with response.entities.
export function entities(state = initialState, action: any): EntitiesState {
  if (action.payload && action.payload.entities) {
    const clone: any = { ...state };
    switch (action.type) {
      // update the competitions with a list of the fetched questions
      case QuestionTypes.GET_QS_SUCCESS: {
        const comp = clone.competitions[action.compId];
        if (comp) comp.questions = action.payload.result.data;
        break;
      }
      case QuestionTypes.POST_Q_SUCCESS: {
        const comp = clone.competitions[action.compId];
        if (comp) comp.questions.push(action.payload.result.data);
        break;
      }
    }

    return merge(clone, action.payload.entities);
  }

  // delete the item from its corresponding entity
  if (action.type === Types.DELETE_ITEM_SUCCESS) {
    const clone: any = { ...state };
    delete clone[action.name][action.payload.data.id];
    return clone;
  }

  // delete the question from its corresponding competition
  if (action.type === QuestionTypes.DEL_Q_SUCCESS) {
    const clone = { ...state };
    const delId = action.payload.data.id;
    delete clone["questions"][delId]; // delete it from questions entity first
    const comp = clone.competitions[action.compId];
    if (comp) {
      const delIndex = comp.questions?.indexOf(delId) as number;
      if (delIndex > -1) comp.questions?.splice(delIndex, 1);
    }
    return clone;
  }

  return state;
}

export const joins = asyncReducer("joins");
export const users = asyncReducer("users", true);
export const admins = asyncReducer("admins", true);
export const news = asyncReducer("news", true);
export const courses = asyncReducer("courses", true);
export const competitions = asyncReducer("competitions", true);
export const support = asyncReducer("support", true);
export const msgs = asyncReducer("msgs", true);
export { default as user } from "./userReducer";
export { default as alerts } from "./alertsReducer";
export { default as questions } from "./questionsReducer";
export { default as statistics } from "./statisticsReducer";
export { default as settings } from "./settingsReducer";
