import { FormEvent } from "react";
import { RouteProps } from "react-router-dom";

export function uniqId() {
  return Math.random().toString(16).slice(2, 9);
}

export function getQuery(
  location: Location | RouteProps["location"] = window.location
) {
  return new URLSearchParams(location.search);
}

// Merge a `source` object to a `target` one level deep
export function merge(target: any, source: any) {
  // don't mutate the source object itself
  source = { ...source };
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object) {
      source[key] = Object.assign({}, target[key], source[key]);
    }
  }

  // Join `target` and `source`
  return Object.assign({}, target, source);
}

// Check if input is filled and if it is, assign className to it indicating that fact
export function checkIfFilled(evt: FormEvent<any>) {
  const curr = evt.currentTarget;
  if (curr.value.length) curr.classList.add("filled");
  else curr.classList.remove("filled");
}

export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const numRegex = /^\d+$/;
export const phoneRegex = /^(\+966|0)\d{9}$/;
export const phonesRegex = /^((\+966|0)\d{9}\s*)+$/
// eslint-disable-next-line
export const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

// credit: https://stackoverflow.com/a/16436975
export function arraysEqual(a: any[], b: any[]) {
  if (a === b) return true;
  if (a.length !== b.length) return false;

  // cloning the source arrays then sorting them;
  a = a.slice();
  b = b.slice();
  a.sort();
  b.sort();

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function matchSize(size: number = 768) {
  return window.matchMedia(`(min-width: ${size}px)`);
}
