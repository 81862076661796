import { QuestionTypes as Types } from "../types";

const initialState = {
  isFetching: false,
  isStoring: false,
};
function reducer(state = initialState, action: any): typeof initialState {
  switch (action.type) {
    case Types.GET_QS_REQUEST:
      return { ...state, isFetching: true };
    case Types.GET_QS_SUCCESS:
    case Types.GET_QS_FAILURE:
      return { ...state, isFetching: false };
    case Types.POST_Q_REQUEST:
    case Types.DEL_Q_REQUEST:
    case Types.UPDATE_Q_REQUEST:
      return { ...state, isStoring: true };
    case Types.POST_Q_SUCCESS:
    case Types.POST_Q_FAILURE:
    case Types.DEL_Q_SUCCESS:
    case Types.DEL_Q_FAILURE:
    case Types.UPDATE_Q_SUCCESS:
    case Types.UPDATE_Q_FAILURE:
      return { ...state, isStoring: false };
    default:
      return state;
  }
}

export default reducer;
