import { Statistics, StatisticsTypes as Types } from "../types";

type DefaultState = {
  isFetching: boolean;
  data?: Statistics;
};
const defaultState: DefaultState = {
  isFetching: false,
};

function reducer(state = defaultState, action: any): DefaultState {
  switch (action.type) {
    case Types.FETCH_STATISTICS_REQUEST:
      return { ...state, isFetching: true };
    case Types.FETCH_STATISTICS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    case Types.FETCH_STATISTICS_FAILURE:
      return {
        isFetching: false,
      };
    default:
      return state;
  }
}

export default reducer;
