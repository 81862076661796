import { UserTypes as Types } from "../types";

type DefaultState = {
  isFetching: boolean;
  isAuthenticated: boolean;
};
const defaultState: DefaultState = {
  isFetching: false,
  isAuthenticated: localStorage.getItem("token") ? true : false,
};

function userReducer(state = defaultState, action: any): DefaultState {
  switch (action.type) {
    case Types.LOGIN_REQUEST:
      return { ...state, isFetching: true };
    case Types.LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
      };
    case Types.LOGIN_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case Types.LOGOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Types.LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
      };
    case Types.LOGOUT_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}

export default userReducer;
