import { SettingsTypes as Types, Settings } from "../types";

interface DefaultState {
  isFetching: boolean;
  isStoring: boolean;
  data?: Settings;
}
const defaultState: DefaultState = {
  isFetching: false,
  isStoring: false,
};

function settingsReducer(state = defaultState, action: any): DefaultState {
  switch (action.type) {
    case Types.GET_SETTINGS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Types.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data,
      };
    case Types.GET_SETTINGS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case Types.UPDATE_SETTINGS_REQUEST:
      return {
        ...state,
        isStoring: true,
      };
    case Types.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        isStoring: false,
        data: action.payload.data,
      };
    case Types.UPDATE_SETTINGS_ERROR:
      return {
        ...state,
        isStoring: false,
      };
    default:
      return state;
  }
}

export default settingsReducer;
