import { Schema } from "normalizr";
import { CALL_API } from "./middlewares/apiService";

export type OnSuccess = (res: any) => void;
export type OnFailure = (err: any) => void;

// user
export enum UserTypes {
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_ERROR = "LOGIN_ERROR",
  LOGOUT_REQUEST = "LOGOUT_REQUEST",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  LOGOUT_ERROR = "LOGOUT_ERROR",
}

// statistics
export enum StatisticsTypes {
  FETCH_STATISTICS_REQUEST = "FETCH_STATISTICS_REQUEST",
  FETCH_STATISTICS_SUCCESS = "FETCH_STATISTICS_SUCCESS",
  FETCH_STATISTICS_FAILURE = "FETCH_STATISTICS_FAILURE",
}

type MonthObj = {[key: string]: number};
export interface Statistics {
  users_count: number;
  support_requests_count: number;
  competitions_count: number;
  courses_count: number;
  males_count: number;
  females_count: number;
  accepted_users_count: number;
  rejected_users_count: number;
  users_enrolled_in_competitions: number;
  users_enrolled_in_courses: number;
  users_in_last_7_months: MonthObj[];
  competitions_in_last_7_months: MonthObj[];
  support_requests_in_last_7_months: MonthObj[];
  courses_in_last_7_months: MonthObj[];
}

/* Here I'm using numeric enum because it is faster in comparison and
in asyncReducer I check for name and action type not only action type */
// asyncReducer Higher Order Reducer Types
export enum AsyncTypes {
  GET_ITEMS_REQUEST,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAILURE,
  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,
  POST_ITEM_REQUEST,
  POST_ITEM_SUCCESS,
  POST_ITEM_FAILURE,
  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,
  CHG_CURR_PAGE,
  RESET
}
export interface AsyncState {
  isFetching: boolean;
  isStoring: boolean;
  // pages consists of arrays of ids
  pages: {
    [key: string]: number[];
  };
  total: number;
  currPage: number;
  // filter & search properties not needed
  queryTerm?: string;
  filterBy?: string;
}

export interface Join {
  id: number;
  name: string;
  phone: number;
  created_at: string;
}

export interface User {
  id?: number;
  parent_id?: number;
  name: string;
  gender: string;
  age: number;
  email: string;
  phone: string;
  status?: number;
}

// Admin
export interface Admin {
  id: number;
  name: string;
  email: string;
  status: number;
  created_at: string;
}
export interface StoreAdmin {
  email: string;
  password: string;
  password_confirmation: string;
  status?: number; // required when edit
}

export interface NewsItem {
  id: number;
  title: string;
  body: string;
  image: string;
  created_at: string;
  gallery: { id: number; image: string; created_at: string }[];
}

export interface StoreNews {
  title: string;
  body: string;
  image?: string;
  gallery: string[];
}

export interface Course {
  id?: number;
  title: string;
  body: string;
  start_date: string;
  end_date: string;
  image?: string;
  created_at?: string;
  users_count?: number;
  status?: 1 | 0;
}

export interface Competition {
  id?: number;
  title: string;
  description: string;
  start_date: string;
  end_date: string;
  status?: 1 | 0;
  image?: string;
  users_count?: 0;
  created_at?: string;
  questions?: any[];
}

export enum QType {
  "multi" = 1,
  "boolean",
  "text",
  "picture",
  "video",
}

export interface Answer {
  id: number;
  answer: string;
  is_true: 0 | 1;
  created_at: string;
}
export interface StoreAnswer {
  question_id?: number;
  answer: string;
  is_true: 0 | 1;
}
export enum AnswerTypes {
  UPDATE_AS_REQUEST = "UPDATE_AS_REQUEST",
  UPDATE_AS_SUCCESS = "UPDATE_AS_SUCCESS",
  UPDATE_AS_FAILURE = "UPDATE_AS_FAILURE",
}
export interface Question {
  id: number;
  title: string;
  type: QType;
  created_at: string;
  answers: number[];
}
export interface StoreQuestion {
  competition_id: number;
  title: string;
  type: QType;
  answers?: StoreAnswer[];
}

export enum QuestionTypes {
  GET_QS_REQUEST = "GET_QS_REQUEST",
  GET_QS_SUCCESS = "GET_QS_SUCCESS",
  GET_QS_FAILURE = "GET_QS_FAILURE",
  POST_Q_REQUEST = "POST_Q_REQUEST",
  POST_Q_SUCCESS = "POST_Q_SUCCESS",
  POST_Q_FAILURE = "POST_Q_FAILURE",
  DEL_Q_REQUEST = "DEL_Q_REQUEST",
  DEL_Q_SUCCESS = "DEL_Q_SUCCESS",
  DEL_Q_FAILURE = "DEL_Q_FAILURE",
  UPDATE_Q_REQUEST = "UPDATE_Q_REQUEST",
  UPDATE_Q_SUCCESS = "UPDATE_Q_SUCCESS",
  UPDATE_Q_FAILURE = "UPDATE_Q_FAILURE",
}

export enum SupportStatus {
  "قيد الإنتظار" = 1,
  "تحت الدراسة",
  "مرفوض",
  "مقبول",
}
type Attachment = {
  id: number;
  file: string;
  created_at: string;
};
export interface Support {
  id: number;
  user_id: number;
  request_for_himself: 0 | 1;
  relative_relation: string;
  support_request_owner: string;
  phone: string;
  amount: string;
  status: SupportStatus;
  reason: string;
  comment: string;
  created_at: string;
  attachments: Attachment[];
}

export interface Msg {
  id: number;
  phone: string;
  message: string;
  is_sent: 1 | 0;
  created_at: string;
}
export interface StoreMsg {
  phones: string[];
  message: string;
}
// Entities
interface EntityType<ItemType> {
  [key: string]: ItemType;
}
export interface EntitiesState {
  joins: EntityType<Join>;
  users: EntityType<User>;
  admins: EntityType<Admin>;
  news: EntityType<NewsItem>;
  courses: EntityType<Course>;
  answers: EntityType<Answer>;
  questions: EntityType<Question>;
  competitions: EntityType<Competition>;
  support: EntityType<Support>;
  msgs: EntityType<Msg>;
}

// API Service middleware
export interface APIMiddlewareAction {
  name?: keyof EntitiesState;
  [key: string]: any; // allow any other data to be passed away
  [CALL_API]: {
    endpoint: string;
    types: [string | AsyncTypes, string | AsyncTypes, string | AsyncTypes];
    schema?: Schema;
    config?: any;
    onSuccess?: OnSuccess;
    onFailure?: OnFailure;
  };
}

// alerts
export enum AlertsTypes {
  SHOW_ALERT = "SHOW_ALERT",
  HIDE_ALERT = "HIDE_ALERT",
}

interface ShowAlertAction {
  type: typeof AlertsTypes.SHOW_ALERT;
  message: string;
  variant?: string;
}
interface HideAlertAction {
  type: typeof AlertsTypes.HIDE_ALERT;
}

export type AlertsActionTypes = ShowAlertAction | HideAlertAction;

// settings
export interface Settings {
  website_name: string;
  website_logo?: string;
  about_us_description: string;
  about_us_image?: string;
  address: string;
  phone: string;
  email: string;
  facebook: string;
  instagram: string;
  whatsapp: string;
  twitter: string;
  linkedin: string;
  contact_us_phrase: string;
  support_request_status : 0 | 1;
}

export enum SettingsTypes {
  GET_SETTINGS_REQUEST = "GET_SETTINGS_REQUEST",
  GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS",
  GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR",
  UPDATE_SETTINGS_REQUEST = "UPDATE_SETTINGS_REQUEST",
  UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS",
  UPDATE_SETTINGS_ERROR = "UPDATE_SETTINGS_ERROR",
}
