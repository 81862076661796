import React, { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideAlert } from "../../redux/actions/alertsActions";
import { State } from "../../redux/reducers/alertsReducer";
import { RootState } from "../../redux/store";

interface Props {}
const Alert: FC<Props> = (props) => {
  const { show, message, variant }: State = useSelector(
    (state: RootState) => state.alerts
  );
  const dispatch = useDispatch();
  const timeoutIdRef = useRef<number>();

  useEffect(() => {
    if (show) {
      clearTimeout(timeoutIdRef.current); // clear old timeout if any

      timeoutIdRef.current = window.setTimeout(() => {
        dispatch(hideAlert());
      }, 5000);
    }
  }, [show]); // eslint-disable-line

  return (
    <div
      className={"alert alert-dismissible fade-down"}
      data-show={show}
      aria-hidden={!show}
      data-variant={variant}
      role="alert"
    >
      {message}
      <button
        // don't make the btn focusable when hidden
        tabIndex={show ? undefined : -1}
        type="button"
        className="btn-close"
        onClick={() => dispatch(hideAlert())}
      ></button>
    </div>
  );
};

export default Alert;
