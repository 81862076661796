import { normalize, Schema } from "normalizr";
import { UserTypes } from "../types";

export const API_ROOT = process.env.REACT_APP_API_URL;
if (typeof API_ROOT === "undefined") {
  const message = "Please Provide REACT_APP_API_URL as an environment variable";
  throw new Error(message);
}

function callApi(endpoint: string, config: any, schema?: Schema) {
  const fullUrl =
    endpoint.indexOf(API_ROOT as string) === -1
      ? API_ROOT + endpoint
      : endpoint;

  return fetch(fullUrl, config).then((response) => {
    return response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return schema ? normalize(json, schema) : json;
    });
  });
}

export const CALL_API = Symbol("Call API");
const apiService = () => (next: any) => (action: any) => {
  const call = action[CALL_API];

  // So the middleware doesn't get applied to every single action
  if (typeof call === "undefined") {
    return next(action);
  }

  let { endpoint, schema, types, config = {}, onSuccess, onFailure } = call;

  // handle specifying invalid properties
  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }

  const [requestType, successType, errorType] = types;

  // add other action data in addition to type
  function actionWith(data: any) {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  }

  // dispatching the request
  next(
    actionWith({
      type: requestType,
    })
  );

  // add baseHeaders to config headers
  const headers: any = {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  config.headers = Object.assign({}, config.headers, headers);

  return callApi(endpoint, config, schema)
    .then((response) => {
      // if you want to do something after success calls
      if (typeof onSuccess === "function") {
        onSuccess(response);
      }
      return next(
        actionWith({
          receivedAt: Date.now(),
          payload: response,
          type: successType,
        })
      );
    })
    .catch((error) => {
      // if you want to do something after failed calls
      if (typeof onFailure === "function") {
        onFailure(error);
      }
      // handle unauthenticated request (happens if token expires or no longer valid for what ever reason)
      if (error.message === "Unauthenticated.") {
        next({
          type: UserTypes.LOGOUT_SUCCESS,
        });
        localStorage.removeItem("token");
      }
      // for further handling of errors
      return next(
        actionWith({
          type: errorType,
          error,
        })
      );
    });
};

export default apiService;
